import { reducer } from './js/app/reducer';
import './SchemeColor';

window.kinosite = {
	reducer,
	additionalModules: {
		gosuslugiOnAfisha: {
			getScript: () => import('./js/templates/gosuslugi/script.html'),
			getTemplate: () => import("./js/templates/gosuslugi/template.html"),
			gosuslugiID: 380564
		}
	}
};
